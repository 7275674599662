<!--
 * @Description: 客户抵扣详情
 * @Author: ChenXueLin
 * @Date: 2021-10-09 09:41:25
 * @LastEditTime: 2022-07-15 15:54:21
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div class="content-view section-wrapper">
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">客户资金信息</div>
            <el-form label-width="140px" :inline="true">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="客户编码">
                    222
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="客户名称">
                    444
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="可抵扣金额">
                    666
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="已用金额">
                    1000
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="可用金额">
                    555
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <!-- 基本信息 end -->
          <!-- 客户资金流水  start -->
          <div class="baseInfo-box">
            <div class="edit-title">客户资金信息</div>
            <!-- 搜索头部 start -->
            <table-title>
              <template v-slot:searchForm>
                <el-form
                  class="search-list"
                  ref="searchForm"
                  :model="searchForm"
                >
                  <el-form-item class="search-item--1" prop="orderNo">
                    <el-input
                      v-model="searchForm.orderNo"
                      placeholder="账单单号"
                      title="账单单号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item class="search-item--1" prop="orderNo">
                    <el-input
                      v-model="searchForm.orderNo"
                      placeholder="提交人"
                      title="提交人"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="orderNo">
                    <e6-date-picker
                      popper-class="no-atTheMoment"
                      label="提交时间"
                      ref="effectiveDatePicker"
                      v-model="searchForm.createTime"
                      title="提交时间"
                      :picker-options="pickerOptions('searchForm.createTime')"
                      :default-time="['00:00:00', '23:59:59']"
                      start-placeholder="提交时间（始）"
                      end-placeholder="提交时间（止）"
                    ></e6-date-picker>
                  </el-form-item>
                  <el-form-item class="search-item--buttons">
                    <el-button type="primary" @click="handleSearch"
                      >查询</el-button
                    >
                    <el-button class="reset" @click="handleReset"
                      >重置</el-button
                    >
                  </el-form-item>
                </el-form>
              </template>
              <template v-slot:operateAction>
                <i class="e6-icon-export_fill" title="导出"></i>
              </template>
            </table-title>
            <!-- 搜索头部 end -->

            <!-- 表格 start -->
            <section class="table-wrapper">
              <el-table
                border
                height="300px"
                :data="tableData"
                highlight-current-row
              >
                <!-- <el-table-column type="selection" width="40"></el-table-column> -->
                <!-- 序号 start -->
                <el-table-column
                  label="序号"
                  width="50"
                  fixed="left"
                  header-align="center"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>
                      {{
                        scope.$index +
                          1 +
                          (searchForm.pageIndex - 1) * searchForm.pageSize
                      }}
                    </span>
                  </template>
                </el-table-column>
                <!-- 序号 end -->
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </section>
            <!-- 表格 end -->

            <!-- 分页 start -->
            <section class="pagination-wrapper fixed-section">
              <el-pagination
                :page-size.sync="searchForm.pageSize"
                :current-page.sync="searchForm.pageIndex"
                :page-sizes="pageSizes"
                :layout="layout"
                :total="total"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </section>
            <!-- 分页 end -->
          </div>
          <!-- 客户资金流水  start -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "customerDeductionDetail",
  components: {},
  data() {
    return {
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      dialogLoading: false,
      addVisible: false,
      addForm: {},
      total: 0,
      columnData: [
        {
          fieldName: "chargeCode",
          display: true,
          fieldLabel: "账单单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "提交人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "chargeTypeCodeName",
          display: true,
          fieldLabel: "提交日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          chargeCode: "11111"
        },
        {
          chargeCode: "155551"
        }
      ] // 表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {},
  created() {}
};
</script>
<style lang="scss" scoped></style>
